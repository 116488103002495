// 切格文章
export const SplitGraph = {
  methods: {
    splitGraph(str, length) {
      let arr = []
      let temp = ''
      for(let i = 0; i < str.length; i++){
        let char = str.charAt(i);
        temp += char;
        if((char === ',' || char === '.' || char === '\n' || char === '，' || char === '。') && temp.length/length >= 1){
          arr.push(temp);
          temp = '';
        }
        if(i === str.length - 1)
          arr.push(temp);
      }
      return arr;
    }
  },
}